<template>
  <app-dialog
    v-model="model"
    data-cy-post-registration-dialog
    :subtitle="$t('registration_request.missing_credits_dialog.subtitle')"
    :title="$t('registration_request.missing_credits_dialog.title')"
    :width="650"
  >
    <div class="grid gap-8">
      <section
        v-for="section in sections.filter((s) => s.requests.length > 0)"
        :key="section.title"
        :data-cy-request-list="section.status"
      >
        <h3 class="mb-3 text-sm font-semibold">
          {{ section.title }}
        </h3>

        <div class="flex flex-wrap gap-2">
          <organization-member-chip
            v-for="request in section.requests"
            :key="request.id"
            :class="section.chipClasses"
            :member="request.member"
            :status="request.status"
          />
        </div>
      </section>
    </div>

    <template #footer>
      <div class="flex items-center gap-4">
        <div class="flex-auto">
          <p class="text-sm">
            {{
              $t(
                "registration_request.missing_credits_dialog.contact_call_description",
              )
            }}
          </p>
        </div>

        <app-button
          class="whitespace-nowrap"
          :href="callScheduleUrl"
          @click="onContactCallButtonClick"
        >
          {{ $t("registration_request.missing_credits_dialog.cta") }}
        </app-button>
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import type { I18nMessage } from "~/core/i18n/i18n.model";
import type { RegistrationRequestView } from "~~/model/organization.model";

const properties = defineProps<{
  modelValue: boolean;
  requests: RegistrationRequestView[];
}>();

const model = useVModel(properties, "modelValue");

const { t } = useI18n();
const sections = computed<
  {
    title: I18nMessage;
    requests: RegistrationRequestView[];
    chipClasses?: string;
    status: DatabaseEnum<"course_session_registration_request_status">;
  }[]
>(() => {
  return [
    {
      title: t(
        "registration_request.missing_credits_dialog.pending_requests_title",
      ),
      requests: properties.requests.filter((r) => r.status === "PENDING"),
      chipClasses: "!bg-gray-50 !ring-gray-100",
      status: "PENDING",
    },
    {
      title: t(
        "registration_request.missing_credits_dialog.accepted_requests_title",
      ),
      requests: properties.requests.filter((r) => r.status === "ACCEPTED"),
      status: "ACCEPTED",
    },
  ];
});

const {
  public: {
    hubspot: { callScheduleUrl },
  },
} = useRuntimeConfig();

const { $analytics } = useNuxtApp();

const onContactCallButtonClick = () => {
  $analytics.track("call_requested", { origin: "course" });
};
</script>
